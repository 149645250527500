import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { Icon, Text, defaultTheme, Dot } from '@poly/admin-book';
import { useNavigate } from '@poly/client-routing';
import { ClientStatuses } from '@poly/constants';
import {
  insertQueryParamsIntoURL,
  forceTitleCase,
  isNilOrEmpty,
} from '@poly/utils';

import { PageTabMap } from '../Clients/constants.js';
import { routesNames } from '../../routes/constants.js';
import { BarChart } from '../HomePage/ChartDashboard/Charts/BarChart.js';
import {
  barBackgroundClientsChartPlugin,
  styledLabelsClientsChartPlugin,
} from './chartPlugins.js';

const {
  colors: { primaryLight, secondaryMid },
} = defaultTheme;

const ClientsChartWrapperS = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 35%;
  height: 450px;
  border: 1px solid #f0f1f1;
  border-radius: 10px;
  padding: 15px;
`;

const ClientsChartHeaderS = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

const ClientsBarsChartWrapperS = styled.div`
  height: 380px;
  width: 100%;
`;

const FlexRowContainerS = styled.div`
  display: flex;
  align-items: center;
  gap: ${R.propOr('10px', 'gap')};
  padding: 0;
  margin: 0;
`;

const clientsChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  hover: { mode: 'nearest' },
  layout: { padding: { left: 0, right: 0, top: 30, bottom: 35 } },
  plugins: {
    title: { display: false },
    legend: { display: false },
    tooltip: { enabled: false },
  },
  scales: {
    x: {
      grid: { display: false },
      border: { display: false },
      ticks: { display: false },
    },
    y: {
      grid: { display: false },
      border: { display: false },
      ticks: {
        color: '#94969C',
        font: { size: 12 },
        callback: (value) => {
          if (value >= 1000) {
            return `${value / 1000}k`;
          }
          return value;
        },
      },
    },
  },
};

// getClientCountsByStatus :: String -> [ClientStatusCount] -> ClientStatusCount
const getClientCountsByStatus = (status) =>
  R.compose(
    R.ifElse(
      isNilOrEmpty,
      R.always({ status, count: 0 }),
      R.pick(['status', 'count']),
    ),
    R.find(R.propEq(status, 'status')),
  );

// getClientsChartConfig :: AccountIndexQueryResult -> [ClientChartConfig]
// ClientChartConfig = {
//   count: Int
//   label: String
//   color: String
//   status: String
// }
const getClientsChartConfig = R.compose(
  R.juxt([
    R.compose(
      R.mergeLeft({
        color: primaryLight,
        label: forceTitleCase(ClientStatuses.ACTIVE),
      }),
      getClientCountsByStatus(ClientStatuses.ACTIVE),
    ),
    R.compose(
      R.mergeLeft({
        color: secondaryMid,
        label: forceTitleCase(ClientStatuses.INACTIVE),
      }),
      getClientCountsByStatus(ClientStatuses.INACTIVE),
    ),
    R.compose(
      R.mergeLeft({ color: '#099250', label: 'Prospects' }),
      getClientCountsByStatus(ClientStatuses.PROSPECT),
    ),
  ]),
  R.pathOr([], ['adminAccountIndex', 'clients', 'countByStatuses']),
);

// getClientsChartBarsData :: [ClientChartConfig] -> Object
const getClientsChartBarsData = R.applySpec({
  labels: R.map(R.prop('label')),
  datasets: R.compose(
    R.of(Array),
    R.mergeLeft({
      label: 'Clients',
      borderRadius: 10,
      barPercentage: 1.0,
      borderSkipped: false,
      categoryPercentage: 0.9,
    }),
    R.mergeAll,
    R.juxt([
      R.compose(R.objOf('data'), R.map(R.prop('count'))),
      R.compose(R.objOf('backgroundColor'), R.map(R.prop('color'))),
    ]),
  ),
});

export function ClientsChart(props) {
  const navigate = useNavigate();

  const clientsChartConfig = getClientsChartConfig(props);

  const clientsChartBarsData = getClientsChartBarsData(clientsChartConfig);

  const handleBarClick = (label) => {
    if (label === 'Prospects') {
      navigate(routesNames.PROSPECTS);
      return;
    }

    navigate(
      insertQueryParamsIntoURL(
        {
          tab:
            label === 'Inactive'
              ? PageTabMap.INACTIVE_CLIENTS
              : PageTabMap.ACTIVE_CLIENTS,
        },
        routesNames.CLIENTS,
      ),
    );
  };

  return (
    <ClientsChartWrapperS>
      <ClientsChartHeaderS>
        <FlexRowContainerS>
          <Icon name="community" size={26} />
          <Text size="14px">Clients</Text>
        </FlexRowContainerS>
        <FlexRowContainerS gap="14px">
          {clientsChartConfig.map(({ label, color }) => (
            <FlexRowContainerS gap="2px" key={label}>
              <Dot color={color} />
              <Text size="12px">{label}</Text>
            </FlexRowContainerS>
          ))}
        </FlexRowContainerS>
      </ClientsChartHeaderS>
      <ClientsBarsChartWrapperS>
        <BarChart
          data={clientsChartBarsData}
          handleClick={handleBarClick}
          options={clientsChartOptions}
          plugins={[
            styledLabelsClientsChartPlugin,
            barBackgroundClientsChartPlugin,
          ]}
        />
      </ClientsBarsChartWrapperS>
    </ClientsChartWrapperS>
  );
}
