import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import { number, string } from 'prop-types';
import { useNavigate } from '@poly/client-routing';

export const Title = styled.p`
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: #94969c;
  font-weight: 400;
  line-height: 21px;
  max-width: 70%;
  font-family: 'TTNormsRegular';
`;

const ProgressBar = styled.div`
  height: 8px;
  background-color: #436cbe;
  border-radius: 100px;
  width: ${R.propOr('0', 'width')};
  margin: 0;
  padding: 0;
  transition: width 1s ease;
`;

const ProgressBarWrapper = styled(ProgressBar)`
  width: 100%;
  background-color: #f0f1f1;
`;

const ProgressBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  cursor: pointer;
`;

const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ProgressBarLabel = styled(Title)`
  font-family: 'TTNormsMedium';
  font-size: 14px;
`;

const ProgressBarValue = styled(ProgressBarLabel)`
  color: #212223;
`;

// getProgressBarWidth :: (Number, Number) -> String
export const getProgressBarWidth = (total, value) =>
  R.ifElse(
    R.always(total),
    R.compose(
      R.concat(R.__, '%'),
      R.toString,
      Math.floor,
      R.multiply(100),
      R.divide(R.__, total),
      R.defaultTo(0),
    ),
    R.always(0),
  )(value);

export function StatusBar({ total, label, value, href }) {
  const navigate = useNavigate();

  const [width, setWidth] = useState('0');

  useEffect(() => {
    setWidth(getProgressBarWidth(total, value));
  }, []);

  const handleNavigate = () => navigate(href);

  return (
    <ProgressBarContainer onClick={handleNavigate}>
      <FlexBetween>
        <ProgressBarLabel>{label}</ProgressBarLabel>
        <ProgressBarValue>{value}</ProgressBarValue>
      </FlexBetween>
      <ProgressBarWrapper>
        <ProgressBar width={width} />
      </ProgressBarWrapper>
    </ProgressBarContainer>
  );
}

StatusBar.propTypes = {
  total: number.isRequired,
  label: string.isRequired,
  value: number,
  href: string,
};
